import Title from "antd/es/typography/Title";
import React, { useEffect, useState } from "react";
import OrderChart from "../charts/OrderChart";
import RestaurantOccupancyRate from "../charts/RestaurantOccupancyRate";
import TableChart from "../charts/TableChart";

const AdminCompManagerHome = () => {
  const [deferredPrompt, setDeferredPrompt] = useState<Event | null>(null);
  useEffect(() => {
    const handleBeforeInstallPrompt = (e: Event) => {
      e.preventDefault();
      setDeferredPrompt(e);
    };

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
    };
  }, []);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      (deferredPrompt as any).prompt();
      (deferredPrompt as any).userChoice.then((choiceResult: any) => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the install prompt");
        } else {
          console.log("User dismissed the install prompt");
        }
        setDeferredPrompt(null);
      });
    }
  };
  return (
    <div className="w-full">
      <div className="grid">
        <div className="col">
          <RestaurantOccupancyRate />
        </div>
        <div className="col">
          <OrderChart />
        </div>
        <div className="col-12 lg:col-6">
          {/* <TableChart /> */}
        </div>
      </div>
    </div>
  );
};

export default AdminCompManagerHome;
